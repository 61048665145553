import * as React from "react";

import Layout from "../components/Layout/Layout";
import ResourcesContainer from "../components/ResourcesPage/ResourcesContainer";
import Seo from "../components/seo";

const ResourcesPage = () => {
  return (
    <Layout vertical={true}>
      <Seo title="Resources" />
      <ResourcesContainer />
    </Layout>
  );
};

export default ResourcesPage;
