import React, { useState } from "react";
import {
  Callout,
  CalloutContentWrapper,
  StyledTitle,
} from "../../styles/StyledComponents";
import styled from "styled-components";
import { colors } from "../../styles/colors";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import ResourceCard from "./ResourceCard";

import EN_PDF from "../../resources/getting-started-en_v2.pdf";
import { pdfjs, Document, Page } from "react-pdf";
import Button from "../Button/Button";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ResourcePageTitle = styled(StyledTitle)`
  font-family: "Lato";
  color: ${colors.lightBlue};
  border-bottom: 5px solid ${colors.orange};
  padding-bottom: 20px;
`;

const ResourceCardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 25px;
  grid-column-gap: 25px;

  @media only screen and (min-width: 1440px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media only screen and (max-width: 991px) {
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (max-width: 478px) {
    grid-template-columns: 1fr;
    grid-row-gap: 40px;
    margin-bottom: 20px;
    margin: 0px 15px;
  }
`;

const PdfWrapper = styled.div`
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 1850px) {
    align-items: center;
    margin-right: 0px;
    margin-bottom: 20px;
    width: 100%;
  }
  @media only screen and (max-width: 991px) {
    display: none;
  }
`;

const PdfControlsWrapper = styled.div`
  margin-top: 15px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const ResourceCallout = styled(Callout)`
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 991px) {
    min-height: 0px;
    padding: 0px;
  }
`;

const ResourceContainer = styled(ResourceCallout)`
  @media only screen and (min-width: 1440px) {
    max-width: 1440px;
  }
`;

const RESOURCES_QUERY = gql`
  query GetResources {
    externalResourceCollection {
      items {
        name
        url
        description
        highlightImage {
          title
          fileName
          url
          width
          height
        }
      }
    }
  }
`;

const Doc = (props) => (
  <Document file={props.pdf} onLoadSuccess={props.onDocumentLoadSuccess} ann>
    <Page pageNumber={props.pageNumber} />
  </Document>
);

const ResourcesContainer = () => {
  const { loading, data: resourcesData } = useQuery(RESOURCES_QUERY);
  const [numPages, setNumPages] = useState<number>(undefined);
  const [pageNum, setPageNum] = useState<number>(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const nextPage = () => {
    setPageNum((page) => (page + 1 > numPages ? 1 : page + 1));
  };

  const prevPage = () => {
    setPageNum((page) => (page - 1 < 1 ? numPages : page - 1));
  };

  return (
    <>
      <Callout type="grey">
        <CalloutContentWrapper>
          <ResourcePageTitle>RESOURCE LIBRARY</ResourcePageTitle>
        </CalloutContentWrapper>
      </Callout>
      <ResourceCallout type="white">
        <ResourceContainer type="white">
          <PdfWrapper>
            <Doc
              pdf={EN_PDF}
              onDocumentLoadSuccess={onDocumentLoadSuccess}
              pageNumber={pageNum}
            />
            <PdfControlsWrapper>
              <Button style={{ marginRight: "10px" }} onClick={prevPage}>
                Prev Page
              </Button>
              <Button onClick={nextPage}>Next Page</Button>
            </PdfControlsWrapper>
          </PdfWrapper>
          <Callout type="white">
            <ResourceCardsContainer>
              {!loading &&
                resourcesData.externalResourceCollection.items.map(
                  (item, i) => <ResourceCard key={i} resource={item} />
                )}
            </ResourceCardsContainer>
          </Callout>
        </ResourceContainer>
      </ResourceCallout>
    </>
  );
};

export default ResourcesContainer;
